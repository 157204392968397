import './App.css';
import boykoinpz from './boykoinpz.jpeg';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <a href="https://www.pornhub.com/" rel="noreferrer" target="_blank">
          <img src={boykoinpz} className="App-logo" alt="logo" />
        </a>
        <p>Boyko Borisov Welcomed in Pazardzhik by Todor Popov</p>
        <p>Kill them all feat Krisko æææææ</p>
        <p>€$£</p>
        <a
          href="https://www.facebook.com/georgi.kenderov"
          rel="noreferrer"
          target="_blank"
        >
          <p>1. Best Basketball player for Bulgaria is Georgi Kenderov</p>
        </a>
      </header>
    </div>
  );
}

export default App;
